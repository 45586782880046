/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { useEffect } from 'react';
import mbpUtil from 'mbp-api-util';
import { useSelector } from 'react-redux';
import { getBrand } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';

const KlarnaOnSiteMessgeScript = () => {
    const brandDetails = useSelector(getBrand);
    const clientId = brandDetails?.['klarna-client-id'] || '';

    useEffect(() => {
        const onSiteMessageScriptURL = mbpUtil.getEnv('KLARNA_ONSITE_MESSAGE_SDK_URL');

        if (typeof window !== 'undefined'
            && clientId
            && onSiteMessageScriptURL
            && !document.querySelector('#klarna-onsite-message-script')) {
            const script = document.createElement('script');
            script.id = 'klarna-onsite-message-script';
            script.async = true;
            script.src = onSiteMessageScriptURL;

            if (mbpUtil.getEnv('APP_VERSION_ENV') === 'production') {
                script.setAttribute('data-environment', 'production');
            } else {
                script.setAttribute('data-environment', 'playground');
            }

            script.setAttribute('data-client-id', clientId);

            document.body.appendChild(script);
        }
    }, [clientId]);

    return null;
};

export default KlarnaOnSiteMessgeScript;
